import React from "react"

import InnerLayout from "../components/InnerLayout"
import InnerWrapper from "../components/InnerWrapper"
import SEO from "../components/seo"

const PrivacyPage = () => {
  return (
    <InnerLayout>
      <SEO title="Privacy"/>
      <InnerWrapper>
        <section className="outer-padding-x outer-padding-y">
          <h1 className="fadein">Privacy</h1>
          <div className="max-width Privacy">
            <br/>
            <br/>
            <h3 className="fadein">Website</h3>
            <p className="fadein">This website uses a privacy-sensitive and GDPR compliant <a href="https://www.netlify.com/products/analytics/" rel="noopener noreferrer" target="_blank">analytics tool</a> provided by Netlify that complies with General Data Protection Regulation (GDPR).</p>
            <br/>
            <br/>
            <h3 className="fadein">Newsletter</h3>
            <p className="fadein">If you sign up to the newsletter you agree to share your first name and email address with me. The newsletter uses <a href="https://mailchimp.com/" rel="noopener noreferrer" target="_blank">Mailchimp</a> email software who process the data. You can at all times unsubscribe and remove your email address from the recipients list and I will not share your email address without your consent under any circumstances.</p>
            <br/>
            <br/>
            <p className="has-small-font-size fadein">This site was built with <a href="https://www.gatsbyjs.org/" rel="noopener noreferrer" target="_blank">gatsby.js</a> and hosted at <a href="https://www.netlify.com/" rel="noopener noreferrer" target="_blank">Netlify</a>.</p>
          </div>
        </section>
      </InnerWrapper>
    </InnerLayout>
  )
}

export default PrivacyPage
